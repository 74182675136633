<template>
  <v-container
    id="admin-storageDrive-view"
    fluid
    tag="section"
  >
    <material-card
      color="primary"
      icon="mdi-file"
    >
      <template #title>
        {{ $t('layout.sidebar.manageMedia') }}
      </template>
      <filter-form-select-app />
      <table-manage-media />
    </material-card>
  </v-container>
</template>

<script>
  import FilterFormSelectApp from '../modules/all/manageMedia/components/FilterFormSelectApp.vue'
  import TableManageMedia from '../modules/all/manageMedia/components/TableManageMedia'

  export default {
    name: 'ManageMedia',
    components: {
      FilterFormSelectApp,
      TableManageMedia,
    },
    // Title page
    metaInfo: {
      titleTemplate: 'Manage Media',
    },
  }
</script>
