<template>
  <div class="mt-5">
    <v-simple-table>
      <thead>
        <tr>
          <th class="primary--text font-weight-bold" v-text="'File Url'" />
          <th class="primary--text font-weight-bold" v-text="'File Type'" />
          <th class="primary--text font-weight-bold" v-text="'Last Modified'" />
          <th class="primary--text font-weight-bold" v-text="'Size'" />
          <th
            class="primary--text font-weight-bold text-center"
            v-text="$t('user.userList.table.actions')"
          />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(file, index) in getDataFiles" :key="index + '_dataItems'">
          <td>
            <v-img v-if="file.fileType === 'photo'" width="150" :src="file.fileURL" cover />
            <a :href="file.fileURL" target="_blank">{{ file.fileURL }}</a>
          </td>
          <td v-text="file.fileType" />
          <td v-text="file.lastModified" />
          <td v-text="fileSize(file.size)" />
          <td class="text-center btn-option">
            <v-btn @click="clickDeleteFile(file.name)">
              Delete
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
// Vuex
import { sync } from 'vuex-pathify'

export default {
  name: 'TableManageMedia',
  computed: {
    ...sync('manageMedia', ['files', 'filterFileType']),
    getDataFiles() {
      if (!this.filterFileType || this.filterFileType === 'All') {
        return this.files || []
      }
      return (this.files || []).filter(dataFilter => dataFilter.fileType === this.filterFileType)
    },
  },
  methods: {
    take_decimal_number(num, n) {
      const base = 10 ** n;
      const result = Math.round(num * base) / base;
      return result;
    },
    fileSize(data) {
      const convertMB = (data * 0.001) / 1024;
      let result = '';
      let unit = '';
      result = this.take_decimal_number(convertMB, 1);
      unit = 'MB';
      if (result > 1024 || result === 1024) {
        result = result / 1024;
        unit = 'GB';
      }
      return result + ' ' + unit;
    },
    async clickDeleteFile(fileName) {
      const confirmed = await this.$swal({
        title: 'Item Delete Confirmation',
        text: 'Are you sure you want to delete this file?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: '#c32c39',
        cancelButtonText: 'Cancel',
        cancelButtonColor: '#9a9ea1',
        reverseButtons: true,
      });
      if (!confirmed.value) return false;
      const resultAPI = await this.$store.dispatch('manageMedia/deleteFile', {
        tokenLogin: this.getTokenLogin(),
        fileName,
      });
      if (!resultAPI.status) {
        this.$toast.error(resultAPI.data.message);
        return false;
      }
      this.$toast.success('Delete Successfuly');
      this.showProgressCircular();
      setTimeout(async () => {
        const resultAPI = await this.$store.dispatch('manageMedia/getFiles', this.getTokenLogin())
        if (!resultAPI.status) {
          if (resultAPI.data.length > 0) {
            resultAPI.data.forEach((element) => {
              const keyObject = Object.keys(element);
              this.$toast.error(keyObject + ': ' + element[keyObject].message);
            });
          } else {
            this.$toast.error(resultAPI.data.message);
          }
          return;
        }
        this.files = resultAPI?.data?.files || [];
      }, 1000);
    },
  },
};
</script>
<style scoped>
.btn-option button {
  color: white;
  margin: 2px;
}
.btn-option button:nth-child(1) {
  background-color: #ac2925;
}
</style>
