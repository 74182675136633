<template>
  <v-row class="px-4">
    <v-col cols="6">
      <v-autocomplete v-model="selectedAppId" :items="dataApps" :filter="filterObject" clearable dense outlined color="blue-grey-lighten-2"
        item-title="title" item-value="id" label="Select App" @change="changeApp">
        <template v-slot:selection="data">
          <v-list-item>
            <v-list-item-avatar>
              <img :src="data.item.image_url">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ data.item.title }} <span style="text-transform:capitalize;">({{ data.item.type }})</span>
              </v-list-item-title>
              <v-list-item-subtitle v-html="data.item.description" />
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:item="data">
          <v-list-item-avatar>
            <img :src="data.item.image_url">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.title }} <span style="text-transform:capitalize;">({{ data.item.type }})</span>
            </v-list-item-title>
            <v-list-item-subtitle v-html="data.item.description" />
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col v-show="selectedAppId && allDataUsers.length" cols="3">
      <v-autocomplete
        v-model="userId"
        :items="optionUsers"
        label="User"
        item-text="title"
        item-value="id"
        dense
        outlined
        @change="getFiles"
      />
    </v-col>
    <v-col v-show="selectedAppId" cols="12" md="3">
      <v-select
        v-model="filterFileType"
        :items="optionFilterFileType"
        label="File Type"
        dense
        outlined
      />
    </v-col>
    <v-col v-show="selectedAppId" cols="12" :md="fileUpload ? 10 : 12">
      <v-file-input v-model="fileUpload" label="Choose File Media" show-size @change="changeUploadFile">
        <template v-slot:append>
          <v-select
            v-model="updateFileType"
            class="pa-3 v-select_format"
            label="File Type"
            hide-details
            underlined
            :items="optionFileType"
          />
        </template>
      </v-file-input>
    </v-col>
    <v-col v-show="fileUpload" cols="12" md="2">
      <v-btn
        color="primary"
        variant="flat"
        @click="submitUploadFile"
      >
        Upload File
      </v-btn>
    </v-col>
    <v-col v-if="fileUpload && updateFileType === 'photo'" cols="12" align="center" justify="center">
      <v-img height="250" :src="urlPreview" cover />
    </v-col>
  </v-row>
</template>

<script>
import { FILE_TYPE } from '../constant'
import { sync, get } from 'vuex-pathify'
export default {
  name: 'FilterFormSelectApp',
  data() {
    const optionFilterFileType = Object.values(FILE_TYPE);
    optionFilterFileType.unshift('All');
    return {
      dataApps: [],
      urlPreview: '',
      fileUpload: null,
      allDataUsers: [],
      updateFileType: FILE_TYPE.PHOTO,
      optionFileType: Object.values(FILE_TYPE),
      optionFilterFileType,
    }
  },
  computed: {
    ...sync('manageMedia', [
      'selectedAppId',
      'files',
      'userId',
      'filterFileType',
    ]),
    optionUsers() {
      const optionUsers = this.allDataUsers.map(dataUser => {
        return {
          title: `${dataUser?.fullName || ''}(${dataUser?.email || ''})`,
          id: dataUser.id,
        }
      })
      optionUsers.unshift({
        title: 'All',
        id: 'all',
      })
      return optionUsers
    },
  },
  async created() {
    this.filterFileType = 'All'
    this.files = []
    this.selectedAppId = ''
    const dataApps = await this.$store.dispatch('manageApps/getDataApps', { tokenLogin: this.getTokenLogin() })
    this.dataApps = this.getAppsOfUser(dataApps).filter(dataApp => dataApp?.dataGroup?.packageMinio)
    if (!this.isRoleUserLogin()) {
      this.allDataUsers = await this.$store.dispatch('adminUser/fetchAllDataUserByRole', { tokenLogin: this.getTokenLogin(), role: 'User' })
    }
  },
  beforeDestroy() {
    this.userId = 'all';
  },
  methods: {
    async getDataApps() {
      const data = await this.$store.dispatch('manageApps/getDataApps', { tokenLogin: this.getTokenLogin(), isShowPublic: true })
      return data
    },
    async getFiles() {
      const resultAPI = await this.$store.dispatch('manageMedia/getFiles', this.getTokenLogin())
      if (!resultAPI.status) {
        return this.$toast.error(resultAPI.data.message)
      }
      this.files = resultAPI?.data?.files || []
    },
    filterObject(item, queryText) {
      if (!queryText) return true;
      const { title = '', description = '' } = item
      return (title + description).toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
    },
    async changeApp() {
      this.updateFileType = FILE_TYPE.PHOTO
      this.filterFileType = 'All'
      if (!this.selectedAppId) {
        this.files = null
      } else {
        this.userId = 'all'
        await this.getFiles()
        this.urlPreview = ''
        this.fileUpload = null
      }
    },
    changeUploadFile() {
      if (this.fileUpload) {
        this.urlPreview = URL.createObjectURL(this.fileUpload)
      } else {
        this.urlPreview = ''
      }
    },
    async submitUploadFile() {
      const formData = new FormData()
      formData.append('file', this.fileUpload, this.fileUpload?.name)
      formData.append('fileType', this.updateFileType)
      const resultAPI = await this.$store.dispatch('manageMedia/uploadFile', {
        tokenLogin: this.getTokenLogin(),
        formData,
      });
      if (!resultAPI.status) {
        return this.$toast.error(resultAPI.data.message)
      }
      this.$toast.success('Upload File')
      await this.getFiles()
      this.urlPreview = ''
      this.fileUpload = null
    },
  },
}
</script>
